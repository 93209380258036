
@font-face {
  font-family: 'GT Walsheim';
  src: url(./fonts/GT-Walsheim-Medium.woff2) format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url(./fonts/GT-Walsheim-Black.woff2) format('woff2');
  font-weight: 800;
}

body {
  background: rgb(46, 67, 199);
  background: radial-gradient(
    circle,
    rgba(46, 67, 199, 1) 0%,
    rgba(34, 45, 102, 1) 100%
  );
  color: white;
  margin: 0;
  font-family: 'GT Walsheim', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
